<template>
    <div class="home body">
        <Header />
        <div role="main" class="main position-relative">
          <carousel v-bind="settings">
            <slide v-for="(item,index) in main" :key="index" :style="`background-image: url(${require(`@/assets/img/bg/${item.src}.jpg`)})`" class="position-relative">
              <div class="container m-auto">
                <div class="d-flex flex-column align-items-center desc mb-3">
                  <h1>{{item.title}}</h1>
                  <p v-html="item.desc" class="mb-4"></p>

                  <router-link to="/pay" target="_blank" class="btn btn-outline btn-rounded btn-light btn-4 px-0">
                      <div class="d-flex justify-content-between align-items-center w-px-180 px-3">
                        <span>TheGoodPay 서비스</span>
                        <i class="fal fa-chevron-right ms-2 fa-lg"></i>
                      </div>
                  </router-link>
                </div>
              </div>
            </slide>

            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>

          <div class="notice-wrap pt-4">
            <div class="col-lg-12">
              <h5 class="mb-2 mb-sm-3 text-white">공지사항</h5>
              <ul class="list list-group">
                <li class="list-group-item p-0" v-for="(item, index) in notice.slice(0,4)" :key="index">
                  <router-link :to="`/post/${item.cate}/${item.idx}`" class="d-block p-2 px-3">
                    <p class="text-truncate mb-0 text-white lh-1">{{ item.title }}</p>
                    <span class="text-white opacity-7">{{ item.date }}</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
            
        </div>
        <!-- <section class="pt-5">
          <div class="container">
            <ul class="portfolio-list portfolio-list-style-2 d-flex flex-wrap">
              <li class="col-sm-6 col-md-4 p-0 mb-3" v-for="(item, index) in mainItem" :key="index">
                <div class="portfolio-item hover-effect-3d text-center">
                  <a href="#">
                    <span class="mb-3">
                      <span class="min-height-300 d-flex align-items-center justify-content-center" style="max-height:300px">
                        <img :src="item.src" class="w-50" alt="">
                      </span>
                    </span>
                  </a>
                  <h3 class="font-weight-bold mb-3">
                    <router-link :to="item.link" class="link-color-dark">{{ item.title }}</router-link>
                  </h3>
                  <p class="text-uppercase lead text-0">{{item.desc}}</p>
                </div>
              </li>
            </ul>
          </div>
        </section> -->
        <router-link to="/contact-us" id="contact" class="btn btn-outline btn-rounded btn-light btn-sticky-primary btn-4">
            <div class="wrap">
              <i class="fas fa-envelope mr-2 fa-lg"></i>
              <span>Contact Us</span>
            </div>
        </router-link>
        <Footer />
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import Header from '@/components/common/MainHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  name: 'Home',
  components:{
    Header, 
    Carousel,
    Slide,
    Pagination,
    Navigation, Footer
  },
  data(){
    return{
      settings: {
        mouseDrag:false,
        wrapAround:false,
        autoplay:3000,
        transition:500
      },
      main:[
        {
          src:'1',
          title:'공유 주차 “마이주차” 플랫폼',
          desc:'주차 정보를 실시간으로 사용자에게 제공하고, <br> 사용 가능한 주차 공간을 매칭시켜주는 서비스'
        },
        {
          src:'2',
          title:'TheGoodPay와 함께하세요.',
          desc:'온ㆍ오프라인 상거래에 필수적인 <br> 통합전자결제 서비스'
        },
        
        // {
        //   src:'4',
        //   title:'전자결제대행의 틀을 바꿉니다.',
        //   desc:'원성글로벌은 전자지급결제대행업을 기반으로<br>플랫폼을 운영하는 기업입니다.'
        // },
      ],
      notice:[
        {
          cate:'news',
          idx: 2,
          title:'에스에이지카타운, 공유주차 플랫폼 활성화를 위한 업무제휴',
          date:'2023-09-08'
        },
        {
          cate:'news',
          idx: 1,
          title:'탐코퍼레이션, 원성글로벌과 골프통합관리 플랫폼 서비스 업무',
          date:'2023-04-27'
        },
        {
          cate:'corpo-disclosure',
          idx: 2,
          title:'제1기 정기주주총회 결과 공고',
          date:'2023-03-14'
        },
        {
          cate:'corpo-disclosure',
          idx: 1,
          title:'제1기 정기주주총회 소집 공고',
          date:'2023-02-20'
        }
      ],
      mainItem:[
        {
          src:require('@/assets/img/business/01.png'),
          title:'The Good Pay',
          desc:'기존 CAT 단말기에 Swipe, IC카드 삽입 리딩의 한계를 극복한 스마트폰의 NFC 및 IC(RF) Card 기반 모바일 결제 서비스',
          link:'/'
        },
        {
          src:require('@/assets/img/business/03.png'),
          title:'마이주차',
          desc:'주차 정보를 실시간으로 정확하게 사용자에게 제공, 사용 가능한 주차 공간을 매칭시켜주는 서비스 플랫폼',
          link:'/'
        },
        {
          src:require('@/assets/img/business/02.gif'),
          title:'Law114',
          desc:'법률적 문제를 겪고 있는 법률 서비스 사용자가 객관적으로 법률 서비스를 받을 수 있도록 정보를 제공하는 플랫폼',
          link:'/'
        },
        {
          src:'https://via.placeholder.com/300',
          title:'pay-system',
          desc:'설명',
          link:'/'
        },
        {
          src:'https://via.placeholder.com/300',
          title:'pay-system',
          desc:'설명',
          link:'/'
        },
      ]
    }
  }
}
</script>

<style lang="scss">
.main .carousel__viewport{
  height: 100vh;
}
.main .carousel__pagination{
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
}
.main .carousel__pagination-button::after{
  background-color: #ffffff9e;
}
.main .carousel__icon{
  fill:#fff;
}
.main .carousel__pagination-button:hover::after, .carousel__pagination-button--active::after{
  background-color: #fff !important;
}
.main .carousel__pagination-button::after{
  border-radius: 50% !important;
  width: 12px !important;
  height: 12px !important;
}
.main .carousel__slide{
  width: 100% !important;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  // background-position: center center;
  align-items: flex-end;
  padding-bottom: 5rem;
  z-index: 1;
  &::before{
    content:'';
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height:100%;
    background-color: rgba(0,0,0,.3);
  }
  .container{
    z-index: 1;
  }
  .desc{
    text-align: start;
    h1{
      font-size: 4em;
      font-weight: bold;
      color: #fff !important;
      margin-bottom: 35px;
    }
    p{
      font-size: 2em;
      color: #fff !important;
      line-height: 1.5;
      text-align: center
    }
  }
}


.notice-wrap{
  text-align: start;
  max-width: 100%;
  width: 350px;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-160%);
  ul{
    text-align: start;
    list-style: none;
    backdrop-filter: blur(3px);

    li{
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}


@media (max-width: 1199.9px){
  .notice-wrap{
    transform: translateX(-135%);
  }
}

@media (max-width: 991.9px){
  .notice-wrap{
    transform: translateX(-100%);
  }
}
@media (max-width: 767.9px){
  .main .carousel__slide .desc h1{font-size: 2em;}
  .main .carousel__slide .desc p{font-size: 1.5em}
  .notice-wrap{
    transform: translateX(-75%);
  }
}
@media (max-width: 575.9px){
  .notice-wrap{
    transform: translateX(-50%);
  }
  #contact{
    right: 20px;
    bottom: 20px;
  }
  
  .carousel__pagination{
    left: 0;
    transform: translate(0);
    padding-left: 20px;
  }
  .carousel__prev, .carousel__next{
    z-index: 9;
  }

  .carousel__slide{
    .container{
      padding-bottom: 220px;
    }
    .desc{
      text-align: center;

      h5{
        font-size: 23px;
        margin-bottom: 10px;
        line-height: 1.5;
      }
      p{
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
}
</style>