export function loadStyles(urls) {
    const links = urls.map(url => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = url;
      document.head.appendChild(link);
      return link;
    });
  
    return () => {
      links.forEach(link => document.head.removeChild(link));
    };
  }