<template>
  <router-view/>
</template>

<style lang="scss">
@import 'assets/css/all.css'; //폰트어썸
@import 'assets/css/custom-bootstrap.scss';

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
body{    
  font-family: "Montserrat", 'Pretendard-Regular', sans-serif;
  word-break: keep-all;
}
#contact{
  position: fixed;
  right: 10vw;
  bottom: 10vh;
  z-index: 99;
}
.main~#footer .footer-copyright.footer-copyright-border-top{
  border: none;
}
</style>
