<template>
    <header id="header" class="header-effect-shrink header-transparent px-0">
        <div class="header-body">
            <!-- <div class="header-top bg-white">
                <div class="header-top-container container">
                    <div class="header-row">
                        <div class="header-column justify-content-start">
                            <span class="d-none d-sm-flex align-items-center">
                                <i class="fas fa-map-marker-alt mr-1"></i>경기도 하남시 미사대로520, CA동 804호
                            </span>
                            <span class="d-none d-sm-flex align-items-center ml-4">
                                <i class="fas fa-phone mr-1"></i>
                                <a href="tel:+1234567890">1533-4178</a>
                            </span>
                        </div>
                        <div class="header-column justify-content-end">
                            <ul class="nav">
                                <li v-if="!login">
                                    <ul class="d-flex p-0" style="list-style: none">
                                        <li class="nav-item">
                                            <router-link class="nav-link" to="/sign">Login</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link class="nav-link" to="/sign">Join Us</router-link>
                                        </li>
                                    </ul>
                                </li>
                                <li class="py-2" v-else>{{id}} 님</li>
                                <li class="nav-item">
                                    <a class="nav-link dropdown-menu-toggle py-2" id="dropdownLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                        English	<i class="fas fa-angle-down fa-sm"></i>
                                    </a>
                                    <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguage">
                                        <li><a class="no-skin"><img src="@/assets/img/blank.gif" class="flag flag-us" alt="English" /> English</a></li>
                                        <li><a class="no-skin"><img src="@/assets/img/blank.gif" class="flag flag-es" alt="Español" /> Español</a></li>
                                        <li><a class="no-skin"><img src="@/assets/img/blank.gif" class="flag flag-fr" alt="Française" /> Française</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="header-container container">
                <div class="header-row">
                    <div class="header-column justify-content-start">
                        <div class="header-logo">
                            <router-link to="/">
                                <img alt="logo" width="127" src="@/assets/img/logo-light.png" class="light-logo">
                                <img alt="logo" width="127" src="@/assets/img/logo.png" class="color-logo">
                            </router-link>
                        </div>
                    </div>
                    <div class="header-column justify-content-center">
                        <div class="header-nav justify-content-lg-center">
                            <div class="header-nav-main header-nav-main-effect-1 header-nav-main-sub-effect-1 px-0">
                                <nav class="collapse">
                                    <ul class="nav flex-column flex-lg-row" id="mainNav">
                                        <li class="dropdown opened">
                                            <router-link to="/company" class="dropdown-item dropdown-toggle">
                                                COMPANY
                                            </router-link>
                                            <ul class="dropdown-menu d-block">
                                                
                                                <li>
                                                    <router-link to="/company" class="dropdown-item dropdown-toggle">회사 개요</router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/vision" class="dropdown-item dropdown-toggle">경영 이념 및 비전</router-link>
                                                </li>
                                                
                                                <li>
                                                    <router-link to="/core-value" class="dropdown-item dropdown-toggle">회사 연혁</router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/client" class="dropdown-item dropdown-toggle">파트너</router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/location" class="dropdown-item dropdown-toggle">오시는 길</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown opened">
                                            <router-link to="/business" class="dropdown-item dropdown-toggle">
                                                BUSINESS
                                            </router-link>
                                            <ul class="dropdown-menu d-block">
                                                <li>
                                                    <router-link to="/business/payment" class="dropdown-item dropdown-toggle">전자결제</router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/business/myparking" class="dropdown-item dropdown-toggle">마이주차</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown opened">
                                            <router-link to="/news" class="dropdown-item dropdown-toggle">
                                                PR
                                            </router-link>
                                            <ul class="dropdown-menu d-block">
                                                <li>
                                                    <router-link to="/news" class="dropdown-item dropdown-toggle" >News</router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/corpo-disclosure" class="dropdown-item dropdown-toggle" >기업공시</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown opened">
                                            <router-link to="/contact-us" class="dropdown-item dropdown-toggle">
                                                CONTACT
                                            </router-link>
                                            <ul class="dropdown-menu d-block">
                                                <li>
                                                    <router-link to="/contact-us" class="dropdown-item dropdown-toggle" >문의하기</router-link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="header-column justify-content-end">
                        <div class="d-none d-sm-flex">
                            <a href="pdf/[사업계획] 01. 사업계획서.pdf" target="_blank" class="btn btn-outline btn-light bg-transparent border-0 btn-sticky-primary btn-4">
                                <span class="wrap">
                                    <span>Download Company Intro</span>
                                    <i class="fas fa-arrow-right ml-2"></i>
                                </span>
                            </a>
                        </div>
                        
                        <button class="header-btn-collapse-nav-light header-btn-collapse-nav ml-3 " data-toggle="collapse" data-target=".header-nav-main nav">
                            <span class="hamburguer">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
  
<script>
export default {
    data(){
        return{
            login:false,
            id:'Daesanace'
        }
    },
    mounted() {
        this.initializeStickyHeader();
    },
    methods: {
        initializeStickyHeader() {
        const self = this;
        const $wrapper = $('#header'); // Replace with your wrapper selector

        const options = {
            wrapper: $wrapper,
            headerBody: $wrapper.find('.header-body'),
            stickyEnabled: true,
            stickyEnableOnBoxed: true,
            stickyEnableOnMobile: true,
            stickyStartAt: 120,
            stickyStartAtElement: false,
            stickySetTop: 0,
            stickyHeaderContainerHeight: false,
            stickyChangeLogo: true,
        };

        this.setOptions(options);
        this.build();
        this.events();
        },
        setOptions(opts) {
            this.options = {
                ...this.defaults,
                ...opts,
                ...(this.$refs.wrapper && this.$refs.wrapper.dataset.pluginOptions),
            };
        },

        build(){
            if (
                !this.options.stickyEnableOnBoxed &&
                $('html').hasClass('boxed') ||
                !this.options.stickyEnabled
            ) {
                return;
            }

            const self = this;
            const $html = $('html');
            const $window = $(window);
            const sideHeader = $html.hasClass('side-header');
            const initialHeaderTopHeight = self.options.wrapper.find('.header-top').outerHeight();
            const initialHeaderContainerHeight = self.options.wrapper.find('.header-container').outerHeight();
            let minHeight;

            $html.addClass('sticky-header-enabled');

            if (parseInt(self.options.stickySetTop) < 0) {
                $html.addClass('sticky-header-negative');
            }

            // Set Start At
            if (self.options.stickyStartAtElement) {
                const $stickyStartAtElement = $(self.options.stickyStartAtElement);

                $window.on('scroll resize', function () {
                self.options.stickyStartAt = $stickyStartAtElement.offset().top;
                });

                $window.trigger('resize');
            }

            // Boxed
            if ($html.hasClass('boxed') && parseInt(self.options.stickyStartAt) === 0 && $window.width() > 991) {
                self.options.stickyStartAt = 30;
            }

            // Define Min Height value
            if (self.options.wrapper.find('.header-top').get(0)) {
                minHeight = initialHeaderTopHeight + initialHeaderContainerHeight;
            } else {
                minHeight = initialHeaderContainerHeight;
            }

            if (self.options.stickyStartAt === 0) {
                self.options.headerBody.css('position', 'fixed');
            }

            // Two Logos Effect
            if (self.options.wrapper.find('.header-logo img').length > 1) {
                self.options.wrapper.find('.header-logo .logo-2').removeClass('d-none');
            }

            $window.on('stickyHeader.activate stickyHeader.deactivate', function () {
                self.options.wrapper.css('min-height', minHeight);
            });

            if (self.options.stickyHeaderContainerHeight) {
                self.options.wrapper.find('.header-container').css('height', self.options.wrapper.find('.header-container').outerHeight());
            }

            self.checkStickyHeader = function () {
                if ($window.scrollTop() >= parseInt(self.options.stickyStartAt)) {
                self.activateStickyHeader();
                } else {
                self.deactivateStickyHeader();
                }
            };

            self.activateStickyHeader = function () {
                if ($window.width() < 992) {
                if (!self.options.stickyEnableOnMobile) {
                    self.deactivateStickyHeader();
                    return;
                }
                } else {
                if (sideHeader) {
                    self.deactivateStickyHeader();
                    return;
                }
                }

                $html.addClass('sticky-header-active');

                if (self.options.stickyStartAt) {
                self.options.headerBody.css('position', 'fixed');
                self.options.headerBody.css('top', self.options.stickySetTop);
                }

                if (self.options.wrapper.hasClass('header-effect-shrink')) {
                if (self.options.wrapper.find('.header-top').get(0)) {
                    self.options.wrapper.find('.header-top').css({
                    height: 0,
                    'min-height': 0,
                    overflow: 'hidden'
                    });
                }

                if (self.options.stickyHeaderContainerHeight) {
                    self.options.wrapper.find('.header-container').css({
                    height: self.options.stickyHeaderContainerHeight,
                    'min-height': 0,
                    overflow: 'hidden'
                    });
                } else {
                    self.options.wrapper.find('.header-container').css({
                    'min-height': 0,
                    // overflow: 'hidden'
                    });
                }

                if (self.options.wrapper.find('.header-logo').hasClass('logo-2')) {
                    self.options.wrapper.find('.header-logo').removeClass('logo-2').addClass('logo-1');
                }

                self.options.wrapper.addClass('header-effect-shrink-active');

                $window.trigger('stickyHeader.activate');
                }
            };

            self.deactivateStickyHeader = function () {
                $html.removeClass('sticky-header-active');

                if (self.options.stickyStartAt) {
                self.options.headerBody.css('position', '');
                self.options.headerBody.css('top', '');
                }

                if (self.options.wrapper.hasClass('header-effect-shrink')) {
                if (self.options.wrapper.find('.header-top').get(0)) {
                    self.options.wrapper.find('.header-top').css({
                    height: '',
                    'min-height': '',
                    overflow: ''
                    });
                }

                if (self.options.stickyHeaderContainerHeight) {
                    self.options.wrapper.find('.header-container').css({
                    height: '',
                    'min-height': '',
                    overflow: ''
                    });
                } else {
                    self.options.wrapper.find('.header-container').css({
                    'min-height': '',
                    overflow: ''
                    });
                }

                if (self.options.wrapper.find('.header-logo').hasClass('logo-1')) {
                    self.options.wrapper.find('.header-logo').removeClass('logo-1').addClass('logo-2');
                }

                self.options.wrapper.removeClass('header-effect-shrink-active');

                $window.trigger('stickyHeader.deactivate');
                }
            };

            self.checkStickyHeader();
            $window.scroll(function () {
                self.checkStickyHeader();
            });
        },
        events() {
            const self = this;
            const $window = $(window);

            $window.on('resize', function () {
                self.checkStickyHeader();
            });

            $window.on('scroll', function () {
                self.checkStickyHeader();
            });
        },
    }
}
</script>
<style scoped>
.btn-light.btn-outline:hover, .btn-light.btn-outline:focus, html.sticky-header-active .btn-sticky-light.btn-outline:hover, html.sticky-header-active .btn-sticky-light.btn-outline:focus{
    color: #2388ed;
}
.color-logo{
    display: none;
}
.header-effect-shrink-active .light-logo{
    display: none;
}
.header-effect-shrink-active .color-logo{
    display: block;
}
</style>