import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
// the good pay home
import HomeView from '../views/Pay/views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import(/* webpackChunkName: "pr" */ '../views/Company/Home.vue')
  },
  {
    path: '/vision',
    name: 'Vision',
    component: () => import(/* webpackChunkName: "pr" */ '../views/Company/Vision.vue')
  },
  {
    path: '/core-value',
    name: 'CoreValue',
    component: () => import(/* webpackChunkName: "pr" */ '../views/Company/CoreValues.vue')
  },
  {
    path: '/client',
    name: 'Client',
    component: () => import(/* webpackChunkName: "pr" */ '../views/Company/Client.vue')
  },
  {
    path: '/location',
    name: 'Location',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Company/Location.vue')
  },
  {
    path: '/business',
    name: 'Business',
    component: () => import(/* webpackChunkName: "pr" */ '../views/Business/Home.vue')
  },
  {
    path: '/business/payment',
    name: 'BusinessDetail',
    component: () => import(/* webpackChunkName: "pr" */ '../views/Business/Detail_payment.vue')
  },
  {
    path: '/business/myparking',
    name: 'BusinessDetail2',
    component: () => import(/* webpackChunkName: "pr" */ '../views/Business/Detail_parking.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "pr" */ '../views/PR/News.vue')
  },
  {
    path: '/data',
    name: 'Data',
    component: () => import(/* webpackChunkName: "pr" */ '../views/PR/Data.vue')
  },
  {
    path: '/corpo-disclosure',
    name: 'CorpoDisclosure',
    component: () => import(/* webpackChunkName: "pr" */ '../views/PR/CorpoDisclosure.vue')
  },
  {
    path: '/post/:category/:idx',
    name: 'Post',
    component: () => import(/* webpackChunkName: "pr" */ '../views/PR/Post.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact/ContactUs.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Other/Terms.vue')
  },
  {
    path: '/sign',
    name: 'Sign',
    component: () => import(/* webpackChunkName: "sign" */ '../views/Other/Sign.vue')
  },

  // **************************** the good pay *************************** //
  {
    path: '/pay',
    name: 'TheGoodPay',
    component: HomeView
  },
  {
    path: '/about',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay/views/about/About.vue'),
    children:[
      {
        path:'/about/info',
        component: () => import(/* webpackChunkName: "about" */ '../views/Pay/views/about/Information.vue')
      },
    ]
  },

  // 고객센터
  {
    path:'/cs/notice',
    name:'notice',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay/views/cs/Notice.vue')
  },
  {
    path:'/cs/notice/:idx',
    name:'notice-detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay/views/cs/NoticeDetail.vue')
  },
  {
    path:'/cs/faq',
    name:'faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay/views/cs/Faq.vue')
  },
  {
    path:'/cs/check-payment',
    name:'check-payment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay/views/cs/CheckPayment.vue')
  },
  {
    path:'/cs/data',
    name:'cs-data',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay/views/cs/Data.vue')
  },
  {
    path:'/cs/data/:idx',
    name:'cs-data-detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay/views/cs/DataDetail.vue')
  },

  // 약관
  {
    path:'/terms/:idx',
    name:'data-detail',
    component: () => import(/* webpackChunkName: "about" */ '../views/Pay/views/other/Terms.vue')
  },
]

const router = createRouter({
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
